





















































































.contact-from {
  p {
    text-align: center;
    &:nth-child(1) {
      font-size: 20px;
      font-weight: bold;
      margin-bottom: 0px;
      color: #009750;
    }
    &:nth-child(2) {
      font-size: 14px;
    }
  }
  .btn-contact {
    width: 100%;
    text-align: center;
    margin: 20px 0px;
    button {
      border-radius: 20px;
    }
  }
}
.support {
  h2.title {
    font-size: clamp(1.2rem, 3vw, 2.2rem);
  }
  .section-1 {
    padding: 150px 0 20px;
    background: #f2f4f6;
    position: relative;
    @media (max-width: 1440px) {
      padding: 120px 0 20px;
    }
  }
  .section-2 {
    padding: 20px 0 10px;
    position: relative;
    padding: 1rem;
    margin: 1rem -15px 0;
    border: solid #f8f9fa;
    border-width: 0.2rem 0 0;
    .container {
      max-width: 768px;
    }
  }
}
